@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dosis&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comfortaa&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Manrope', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.active {
  text-decoration: underline;
  text-underline-offset: 6px;
  font-weight: bolder;
}

#escudo {
  text-underline-offset: 1px;
}

#input-jugadores,
#input-tecnicos {
  background-color: transparent;
  width: 90%;
}

#input-jugadores:focus,
#input-tecnicos:focus {
  outline: 0;
}

.MuiAutocomplete-input {
  padding: 0 !important;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  transform: translate(14px, 10px) scale(1) !important;
  -webkit-transform: translate(14px, 10px) scale(1) !important;
}

.MuiOutlinedInput-notchedOutline {
  border-color: rgb(22 163 74) !important;
}

.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: rgb(22 163 74) !important;
  font-weight: bold;
}

#fechaBusq:focus {
  outline: 0;
}

/* The Modal (background) */
.modal {
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

/* Collapsibles */
.Collapsible__trigger {
  display: block;
  position: relative;
}

.Collapsible__trigger:after {
  font-family: 'FontAwesome';
  content: '\f107';
  position: absolute;
  right: 10px;
  top: 0px;
  display: block;
  transition: transform 300ms;
}

.Collapsible__trigger.is-open:after {
  transform: rotateZ(180deg);
}

.collapsible-planteles .Collapsible__trigger:after {
  top: 10px;
}

/* Animation loading home */
@keyframes loadingHomeAnim {
	0% {
		transform: scale(1);
	}

	50% {
		transform: scale(1.1);
	}

	100% {
		transform: scale(1);
	}
}

.loadingHome {
  animation: loadingHomeAnim 2s ease 0s infinite normal forwards;
}